import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_EXTENSIONS } from '@/constants'
import {
  InnerUploadFile,
  SignedUploadSpolightURLResult,
  SignedUploadURLResult,
  UploadParams,
} from '@/types'
import { generateThumbnail, getImageResolution, rest } from '@/utils'

const getSignedUploadURLByExt = async <T = SignedUploadURLResult>(
  url: string,
  data: {
    ext: string
    content_type: string
  },
): Promise<T> => {
  const res = await rest.post(url, data)
  return res.data
}

export const getPublicSignedUploadURL =
  async (): Promise<SignedUploadSpolightURLResult> => {
    return getSignedUploadURLByExt('/v1/spotlight/upload-url', {
      ext: 'mp4',
      content_type: 'video/mp4',
    })
  }

export const uploadFileToUrl = async ({
  key,
  url,
  file,
  onProgress,
  onSuccess,
  onError,
}: UploadParams & { url: string; key: string }): Promise<void> => {
  try {
    const mime = 'image/jpeg'

    const { dataUrl: thumbnailDataUrl } = await generateThumbnail(file, mime)

    try {
      const res = await rest.put(url, file, {
        onUploadProgress: (e) => {
          const { loaded, total } = e
          const percent =
            total && total > 0 ? Math.round((loaded / total) * 100) : 0
          onProgress?.({ percent })
        },
        headers: {
          'Content-Type': file.type,
          'x-goog-acl': 'public-read',
        },
      })
      if (res.status !== 200) {
        throw new Error((res as any).error ?? 'Upload Failed')
      }
    } catch (error) {
      onError?.(error)
      throw error
    }

    const fileUrl = URL.createObjectURL(file)

    const { width, height } = await getImageResolution(fileUrl)

    onSuccess?.({
      url: fileUrl,
      thumbnailUrl: thumbnailDataUrl,
      fileId: key,
      width,
      height,
    })
  } catch (error) {
    onError?.(error)
    throw error
  }
}

export const upload = async ({
  name,
  file,
  onProgress,
  onSuccess,
  onError,
}: UploadParams): Promise<void> => {
  const realName = name ?? (file instanceof File ? file.name : '') ?? ''
  const fileExt = realName?.split('.').pop()?.toLowerCase()
  const formatedExt = `.${fileExt}`
  const allowedExts = [...ALLOWED_IMAGE_EXTENSIONS, ...ALLOWED_VIDEO_EXTENSIONS]

  try {
    if (!fileExt || !allowedExts.includes(formatedExt)) {
      onError?.(new Error('Invalid file type'))
      return
    }

    const { url, key } =
      (await getSignedUploadURLByExt('/v1/jobs/upload-url', {
        ext: fileExt,
        content_type: file.type,
      })) ?? {}

    if (!url || !key) {
      onError?.(new Error('Failed to get signed upload URL'))
      return
    }

    try {
      const res = await rest.put(url, file, {
        // rest is an axios instance
        onUploadProgress: (e) => {
          const { loaded, total } = e
          const percent =
            total && total > 0 ? Math.round((loaded / total) * 100) : 0
          onProgress?.({ percent })
        },
        headers: {
          'Content-Type': file.type,
          'x-goog-acl': 'public-read',
        },
      })
      if (res.status !== 200) {
        throw new Error((res as any).error ?? 'Upload Failed')
      }
    } catch (error) {
      onError?.(error)
      throw error
    }

    const { dataUrl: thumbnailUrl } = await generateThumbnail(
      file,
      'image/png',
      200,
    )

    const fileUrl = URL.createObjectURL(file)

    const { width, height } = await getImageResolution(fileUrl)

    onSuccess?.({
      url: fileUrl,
      thumbnailUrl,
      fileId: key,
      width,
      height,
    })
  } catch (error) {
    onError?.(error)
    throw error
  }
}

export const publicUpload = async ({
  filepath,
  file,
  onProgress,
  onSuccess,
  onError,
}: Omit<UploadParams, 'name'> & { filepath: string }): Promise<void> => {
  const realName = filepath ?? (file instanceof File ? file.name : '') ?? ''
  const fileExt = realName?.split('.').pop()?.toLowerCase()
  const formatedExt = `.${fileExt}`
  const allowedExts = [...ALLOWED_IMAGE_EXTENSIONS, ...ALLOWED_VIDEO_EXTENSIONS]

  try {
    if (!fileExt || !allowedExts.includes(formatedExt)) {
      onError?.(new Error('Invalid file type'))
      return
    }

    const res = ((await rest.post('/v1/g/upload-url', {
      filepath,
      content_type: file.type,
    })) ?? {}) as any

    const { upload_id, upload_url } = res?.data ?? {}
    if (!upload_id || !upload_url) {
      onError?.(new Error('Failed to get signed upload URL'))
      return
    }

    try {
      const res = await rest.put(upload_url, file, {
        // rest is an axios instance
        onUploadProgress: (e) => {
          const { loaded, total } = e
          const percent =
            total && total > 0 ? Math.round((loaded / total) * 100) : 0
          onProgress?.({ percent })
        },
        headers: {
          'Content-Type': file.type,
          // 'x-goog-acl': 'public-read',
        },
      })
      if (res.status !== 200) {
        throw new Error((res as any).error ?? 'Upload Failed')
      }
    } catch (error) {
      onError?.(error)
      throw error
    }

    const uploadRes: any = await rest.get(`/v1/g/upload/${upload_id}/complete`)

    const { dataUrl: thumbnailUrl } = await generateThumbnail(
      file,
      'image/png',
      200,
    )

    const { width, height } = await getImageResolution(
      uploadRes?.data?.fetch_url,
    )

    onSuccess?.({
      url: uploadRes?.data?.fetch_url,
      thumbnailUrl,
      fileId: uploadRes?.data?.result_key,
      width,
      height,
    })
  } catch (error) {
    onError?.(error)
    throw error
  }
}

export const uploadPromise = async (
  params: Pick<UploadParams, 'file' | 'name'>,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    await upload({
      ...params,
      onSuccess: (data) => {
        resolve(data.fileId)
      },
      onError: reject,
    })
  })
}
